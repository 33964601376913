import * as React from "react";

import Layout from "../../components/layout";

import "./style.css";

const HitsPage = () => {
  return (
    <Layout title="hits | grac :)">
      <div id="hits-page">
        <h1>Hits</h1>
        <p>Unique visitors since Jan 23, 2022:</p>
        <img src="https://hitwebcounter.com/counter/counter.php?page=7934349&style=0007&nbdigits=6&type=ip&initCount=0" title="Free Counter" Alt="web counter"   border="0" />
      </div>
    </Layout>
  );
};

export default HitsPage;
